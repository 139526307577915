import React, { useState, useEffect } from 'react';
import '../Styles/signup.css';
import { Link, useNavigate } from 'react-router-dom'; 
import axios from 'axios';
import { port } from '../port/portno.js';
import AutoTyping from './AutoTyping'; 
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';

function SignUp() {
  const [username, setUsername] = useState("");
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [confpassword, setConfPassword] = useState("");
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false); 

  const navigate = useNavigate(); 

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (password !== confpassword) {
      setError("Passwords do not match!");
      return;
    }

    // Construct full email address with @borgwarner.com
    const email = `${username}@borgwarner.com`;

    const user = {
      email: email,
      name: name,
      password: password
    };

    setIsLoading(true); 
    try {
      const response = await axios.post(`${port}/register`, user);
      setSuccessMessage(response.data.message); 
      setError("");  
      navigate('/getotp', { state: { email: email } });
    } catch (error) {
      if (error.response && error.response.data && error.response.data.message) {
        setError(error.response.data.message);
      } else {
        console.error('Error registering user:', error);
        setError('User with this email already exists and is active.');
      }
    } finally {
      setIsLoading(false); 
    }
  };

  useEffect(() => {
    setError("");
  }, []);

  return (
    <div className="login-container">
      <div className="login-left">
        <div className="intro">
          <div className="au">
            <p>
              <AutoTyping 
                texts={['A journey of flavors that speak to the soul and memories in every bite.']} 
                speed={100} 
                delay={1500}
              />
            </p>
          </div>
        </div>
      </div>
      <div className="login-right">
        <div className="rightLogo">
          <img src="./images/amd.png" alt="Amddas Foods Logo" />
        </div>
        
        <h2>Hello, Welcome to</h2>
        <h2><span>Amddas Foods</span></h2>
        
        <form onSubmit={handleSubmit}>
          {error && <p style={{ color: 'red' }}>{error}</p>}
          {successMessage && <p style={{ color: 'green' }}>{successMessage}</p>}
          
          {/* Modified email input with InputAdornment */}
          <TextField 
            label="Email"
            variant="outlined"
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
            fullWidth
            margin="normal"
            InputProps={{
              endAdornment: <InputAdornment position="end">@borgwarner.com</InputAdornment>,
            }}
            placeholder="Enter email username"
          />
          <TextField 
            label="Name" 
            variant="outlined" 
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
            fullWidth
            margin="normal"
          />
          <TextField 
            label="Password" 
            variant="outlined" 
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            fullWidth
            margin="normal"
          />
          <TextField 
            label="Confirm Password" 
            variant="outlined" 
            type="password"
            value={confpassword}
            onChange={(e) => setConfPassword(e.target.value)}
            required
            fullWidth
            margin="normal"
          />
          
          <div className="but">
            <button 
              type="submit" 
              className="signup-btn"
              disabled={isLoading}
            >
              {isLoading ? 'Signing up...' : 'Signup'}
            </button>
            <Link to='/'>
              <button type="button" className="login-btn">Login</button>
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
}

export default SignUp;
